/* CSS files for plugins */
@import './plugins/feedback.css';
@import './plugins/framegrid.css';
@import './plugins/highcharts.css';
@import './plugins/reactselect.css';
@import './plugins/rc-slider.css';

/* Local CSS */
@import './button.css';
@import './colors.css';
@import './fonts.css';
@import './neutral-colors.css';
@import './primary-colors.css';
@import './success-colors.css';
@import './warning-colors.css';

@import './variables.css'; /* Dark mode and light mode variables */


html {
    background-color: var(--root-background-color);
    overflow-y: scroll;
}

body {
    color: var(--root-text-color);
    background-color: var(--root-background-color);
    margin: 0;
    font-family: 'LexendDecaRegular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    background-color: var(--root-background-color);
}

.App {
    text-align: center;
    height: 100vh !important;
}


.card {
    color: var(--root-text-color);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body.modal-open {
    padding-right: 0px !important;
}

.modal-backdrop{
    --bs-backdrop-bg: var(--bs-backdrop) !important;
}

/* Light Mode */
.thin-white-border-for-dark-mode {
    border: none; /* No border in light mode */
}

/* Dark Mode */
.dark-mode .thin-white-border-for-dark-mode {
    border: 1px solid #828282 !important;
    border-width: 1px !important;
}

.dropdown-menu::-webkit-scrollbar-thumb {
    background-color: darkgray;
    border-radius: 8px;
    background-clip: padding-box;
}

.dropdown-menu::-webkit-scrollbar {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 5px;
    background-color: inherit;
    width: 12px;
}

/* ### Css for react-dropzone ### */
/* Light Mode Variables */
:root {
    --dropzone-border-color: #7ca1c5;
    --dropzone-bg-color: #edf4fa;
    --dropzone-text-color: black;
    --dropzone-focus-color: #2196f3;
}

/* Dark Mode Variables */
.dark-mode {
    --dropzone-border-color: #4e5662;
    --dropzone-bg-color: #1e1e2f;
    --dropzone-text-color: white;
    --dropzone-focus-color: #64b5f6;
}

/* Dropzone Styling */
.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: var(--dropzone-border-color) !important;
    border-style: dashed !important;
    background-color: var(--dropzone-bg-color);
    color: var(--dropzone-text-color);
    outline: none;
    transition: border 0.24s ease-in-out;
    cursor: pointer !important;
    pointer-events: all !important;
}

.dropzone:focus {
    border-color: var(--dropzone-focus-color);
}

/* ### Customized Bootstrap components ### */
.form-check-input:checked {
    background-color: #0b3f70 !important;
    border-color: #0b3f70 !important;
}

.form-check-input{
    --bs-form-check-bg: var(--form-check-bg) !important;
}

/* Hide the small dropd own arrow on drop downs because we want to replace it with the material design drop down arrow icon */
.dropdown ::after {
    display: none !important;
}

/* Light Mode */
:root {
    --accordion-icon-light: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --accordion-icon-dark: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/* Apply the icon */
.accordion-button {
    --bs-accordion-btn-icon: var(--accordion-icon-light);
    --bs-accordion-btn-active-icon: var(--accordion-icon-light);
}

.dark-mode .accordion-button {
    --bs-accordion-btn-icon: var(--accordion-icon-dark);
    --bs-accordion-btn-active-icon: var(--accordion-icon-dark);
}

.remotive-modal-header-bg{
    background-color: var(--modal-header);
}

/* Override Bootstrap Dropdown styles */
.dropdown-menu {
    background-color: var(--primary-5) !important;
    color: var(--dark);
    border: 1px solid var(--primary-20) !important;
    --bs-dropdown-min-width: 2rem !important;
    
}

.dropdown-menu .dropdown-item {
    color: var(--bs-dropdown-color);
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
    background-color: var(--bs-dropdown-link-hover-bg);
    color: var(--bs-dropdown-link-hover-color);
}

/* Hide the small drop down arrow on drop ups because it interferes with the mobile navbar */
.dropup ::after {
    display: none !important;
}

.border-light-gray {
    border-color: #d2d2d2 !important;
}

.progress-bar {
    background-color: #0b3f70 !important;
}

/* Hide the blue outline shadow and underline when an accordion is expanded */
.accordion-button:focus {
    box-shadow: none !important;
    border-color: rgba(0, 0, 0, 0.125) !important;
}

/* Hide the blue background of the accordion exapnd/collapse button that spans across the entire accordion */
.accordion-item {
    background-color: transparent !important;
}

/* Remove the font color change that is applied to the header when an accordion is expanded */
.accordion-button:not(.collapsed) {
    color: inherit !important;
}

.accordion-button {
    background-color: transparent !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 8px !important;
    padding-top: 8px !important;
}

/* ### Flexbox short hand ### */

/* This must be used on each parent if there are nestled flexbox elements that needs to use text-truncate */
.flex-truncate {
    min-width: 0;
}

/* ### Styling for hubspot chat */
#hubspot-messages-iframe-container.widget-align-right {
    margin-bottom: 50px;
}

.initial-message-bubble {
    background-color: #fffd !important;
}

/* ### Styling for feedback plugin ### */
#feedback-submit {
    border-radius: 50px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;

    background-color: #16a468 !important;
    color: #fff !important;
    border-color: #16a468 !important;
    border-width: 2px !important;
}

#feedback-loading {
    border-radius: 50px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;

    background-color: #16a468 !important;
    color: #fff !important;
    border-color: #16a468 !important;
    border-width: 2px !important;
}

#feedback-back {
    border-radius: 50px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;

    background-color: #05315a !important;
    color: #fff !important;
    border-color: #05315a !important;
    border-width: 2px !important;
}

.feedback-btn-wrapper {
    opacity: 0.9 !important;
    margin-bottom: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.feedback-wrapper {
    opacity: 0.9 !important;
    margin-bottom: 1rem !important;
    margin-right: 1rem !important;
}

.feedback-main {
    font-family: 'LexendDecaRegular' !important;
}

#feedback-btn {
    border-radius: 25px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

#feedback-email {
    font-family: 'LexendDecaRegular' !important;
    font-size: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
}

#feedback-message {
    font-family: 'LexendDecaRegular' !important;
    font-size: 14px;
}

#feedback-back {
    font-family: 'LexendDecaRegular' !important;
}

#feedback-submit {
    font-family: 'LexendDecaRegular' !important;
}

#feedback-btn span {
    font-size: 12px !important;
    font-family: 'LexendDecaRegular' !important;
}

.popover-arrow {
    border-width: 0px !important;
}

.opacity-65 {
    opacity: 0.65 !important;
}

.recording-session-tab-parent-active li {
    z-index: 9999;
}

.max-z-index {
    z-index: 9999 !important;
}

/* ********************** Custom bootstrap ********************** */

.modal-content {
    background-color: var(--white); /* Use modal-specific background */
    color: var(--bs-modal-color, inherit); /* Ensure text color matches modal context */
}

.no-bg-modal {
    background-color: transparent !important;
    border: 0px !important;
}

.accordion-button:not(.collapsed) {
    box-shadow: unset !important;
}

.clickable-underlined-on-hover:hover {
    text-decoration: underline;
    cursor: pointer;
}

.clickable-underlined-on-hover {
    text-decoration: none;
}

/* ********* CUSTOM AG GRID ************* */
.ag-root-wrapper {
    border-radius: 0.5rem !important;
    border: 0 !important;
}


.broker-wrapper-border{
   border-color: var(--white) !important; 
}

/* Scrollbar styling for WebKit browsers (Chrome, Edge, Safari) */
::-webkit-scrollbar {
    width: 12px !important;
    height: 12px !important;
}

::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color) !important;
    border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color) !important;
    border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-hover-color) !important;
}

/* Firefox support */
* {
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color) !important;
    scrollbar-width: thin !important;
}

/* Blink animation */
.blink {
    animation: blink 1s steps(1, end) infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}