/* Common Variables */
:root {
    --feedback-primary-bg: #16a468;
    --feedback-primary-color: #fff;
    --feedback-primary-border: #16a468;

    --feedback-secondary-bg: #05315a;
    --feedback-secondary-color: #fff;
    --feedback-secondary-border: #05315a;

    --feedback-border-radius: 50px;
    --feedback-padding: 7px 20px;
    --feedback-font-family: 'LexendDecaRegular';
    --feedback-email-font-size: 12px;
    --feedback-message-font-size: 14px;
    --feedback-btn-font-size: 12px;

    --feedback-main-bg: #ffffff; /* Dark mode background for .feedback-main */
    --feedback-main-color: #000000;
    
    --feedback-header-bg: #05315a;
    --feedback-header-color: #fff;
}

/* Dark Mode Overrides */
.dark-mode {
    --feedback-primary-bg: #0d6c47;
    --feedback-primary-color: #e0e0e0;
    --feedback-primary-border: #0d6c47;

    --feedback-secondary-bg: #01243d;
    --feedback-secondary-color: #e0e0e0;
    --feedback-secondary-border: #01243d;

    --feedback-main-bg: #1a1a1a;
    --feedback-main-color: #e0e0e0;
    --feedback-header-bg: #000000;
    --feedback-header-color: #ffffff;
}

#feedback-close{
    background-color: var(--feedback-header-bg) !important;
    color: var(--feedback-header-color) !important;
    border: 0 !important;
}

.feedback-header{
    background-color: var(--feedback-header-bg) !important;
    color: var(--feedback-header-color) !important;
}

/* Feedback Buttons */
#feedback-submit,
#feedback-loading {
    border-radius: var(--feedback-border-radius) !important;
    padding: var(--feedback-padding) !important;
    background-color: var(--feedback-primary-bg) !important;
    color: var(--feedback-primary-color) !important;
    border-color: var(--feedback-primary-border) !important;
    border-width: 2px !important;
}

#feedback-back {
    border-radius: var(--feedback-border-radius) !important;
    padding: var(--feedback-padding) !important;
    background-color: var(--feedback-secondary-bg) !important;
    color: var(--feedback-secondary-color) !important;
    border-color: var(--feedback-secondary-border) !important;
    border-width: 2px !important;
}

.feedback-item {
    background-color: var(--feedback-main-bg) !important;
    border: 0 !important;
    color: var(--feedback-main-color) !important;
}

.feedback-item:hover {
    background-color: var(--feedback-main-color) !important;
    border: 0 !important;
    color: var(--feedback-main-bg) !important;
}

/* Wrapper Styles */
.feedback-btn-wrapper,
.feedback-wrapper {
    margin-bottom: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.feedback-wrapper {
    margin-bottom: 1rem !important;
    margin-right: 1rem !important;
}

/* Feedback Form Styles */
.feedback-main {
    font-family: var(--feedback-font-family) !important;
    background-color: var(--feedback-main-bg) !important;
    color: var(--feedback-main-color) !important;
}

#feedback-btn {
    border-radius: calc(var(--feedback-border-radius) / 2) !important;
    padding: 1rem 16px !important;
}

#feedback-email {
    font-family: var(--feedback-font-family) !important;
    font-size: var(--feedback-email-font-size);
    padding: 4px 4px 4px 4px;
}

#feedback-message {
    font-family: var(--feedback-font-family) !important;
    font-size: var(--feedback-message-font-size);
}

#feedback-back,
#feedback-submit {
    font-family: var(--feedback-font-family) !important;
}

/* Button Text */
#feedback-btn span {
    font-size: var(--feedback-btn-font-size) !important;
    font-family: var(--feedback-font-family) !important;
}
