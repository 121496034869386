/* Light Mode Variables */
:root {
    --root-text-color: black;
    --root-background-color: #eaf5ff;

    --main: #eaf5ff;
    --white: #fff;
    --dark: #191e23;
    --secondary: #6c757d;
    --white-absolute: #fff;

    --bs-tertiary-bg: var(--primary-0);

    /* Primary Colors */
    --primary-0: #fcfcff;
    --primary-5: #f1f6fa;
    --primary-10: #edf4fa;
    --primary-20: #d2e1ee;
    --primary-30: #b7cde2;
    --primary-40: #7ca1c5;
    --primary-50: #4375a5;
    --primary-50-absolute: #4375a5;
    --primary-60: #0b3f70;
    --primary-70: #05315a;
    --primary-80: #002649;
    --primary-90: #001c36;
    --primary-100: #00182e;

    /* Warning Colors */
    --warning-10: #fff9e5;
    --warning-20: #fff5d7;
    --warning-30: #ffedb4;
    --warning-40: #ffe176;
    --warning-50: #ffd642;
    --warning-60: #f2bd00;
    --warning-70: #e7a600;
    --warning-80: #e78b00;
    --warning-90: #e76100;
    --warning-100: #e83a03;

    /* Success Colors */
    --success-10: #e5fae7;
    --success-20: #d9f9dc;
    --success-30: #b6f0c3;
    --success-40: #88ea9e;
    --success-50: #59d48a;
    --success-60: #1ac472;
    --success-70: #16a468;
    --success-80: #0b8551;
    --success-90: #005e3c;
    --success-100: #044132;

    /* Neutral Colors */
    --neutral-10: #ffffff;
    --neutral-20: #f9fafb;
    --neutral-30: #edf0f3;
    --neutral-40: #dde1e6;
    --neutral-50: #d0d4d9;
    --neutral-60: #aaafb6;
    --neutral-70: #838d95;
    --neutral-80: #636b75;
    --neutral-90: #3f4247;
    --neutral-100: #0e0f11;

    --gradient-start: #05345a;
    --gradient-end: #001324;
    --gradient-horizontal-start: #0f68b1;
    --gradient-horizontal-end: #001324;
    --gradient-sm-start: #05345a;
    --gradient-sm-end: #001324;

    --modal-header: #05315a;

    --pre-background: #f4f4f4;
    --pre-border: #ddd;
    --pre-border-left: #f36d33;
    --pre-text-color: #666;

    --btn-success-bg: #16a468;
    --btn-success-text: #fff;
    --btn-success-border: #16a468;
    --btn-success-hover-bg: #044132;
    --btn-success-hover-border: #044132;
    --btn-success-active-bg: #fff;
    --btn-success-active-text: #16a468;
    --btn-success-active-border: #fff;
    --btn-disabled-text: #fff;
    --btn-disabled-bg: #b4b3b3;
    --btn-no-bg-disabled-color: #b4b3b3;
    --btn-disabled-border: #b4b3b3;
    --btn-primary-bg: #05315a;
    --btn-primary-hover-bg: #4375a5;
    --btn-primary-text: #fff;
    --btn-primary-dark-bg: #00182e;
    --btn-primary-dark-hover-bg: #0b3f70;
    --btn-primary-dark-text: #fff;
    --btn-secondary-bg: #fff;
    --btn-secondary-text: #05315a;
    --btn-secondary-border: #05315a;
    --btn-no-bg-text: #0b3f70;
    --btn-no-bg-hover-text: #7ca1c5;
    --btn-tab-text: #7ca1c5;
    --btn-tab-hover-text: #00182e;
    --btn-nav-text: #59d48a;
    --btn-nav-hover-text: #fff;
    --btn-transparent-bg: transparent;
    --btn-transparent-text: #fff;
    --btn-transparent-text-hover: #000;
    --btn-transparent-border: #fff;

    /* General text and background */
    --bs-body-color: var(--root-text-color); /* Inherit from your variables.css */
    --bs-body-bg: var(--primary-0);

    /* Buttons */
    --bs-btn-primary-bg: #007bff; /* Primary button background */
    --bs-btn-primary-color: white; /* Primary button text */
    --bs-btn-secondary-bg: #6c757d; /* Secondary button background */
    --bs-btn-secondary-color: white;

    /* Inputs */
    --bs-form-control-bg: #f8f9fa; /* Input background */
    --bs-form-control-color: var(--root-text-color); /* Input text color */
    --bs-input-border-color: #ced4da; /* Input border */
    --form-check-bg: white !important;

    /* Cards */
    --bs-card-bg: var(--root-background-color);
    --bs-card-color: var(--text-color);
    --bs-card-border-color: #dee2e6;

    /* Modals */
    --bs-modal-bg: var(--white);
    --bs-modal-color: var(--root-text-color);
    --bs-backdrop: rgb(0, 0, 0); /* Set your desired color and opacity */

    /* Alerts */
    --bs-alert-bg: #f8d7da;
    --bs-alert-border-color: #f5c6cb;
    --bs-alert-color: #721c24;

    /* Tooltips */
    --bs-tooltip-bg: #343a40;
    --bs-tooltip-color: white;

    --bs-border-color: #dee2e6;

    /* Dropdown */
    --bs-dropdown-bg: #d6d6d6; /* Light background */
    --bs-dropdown-color: #000000 !important; /* Dark text */
    --bs-dropdown-border-color: #ced4da; /* Light border */
    --bs-dropdown-link-hover-bg: #e9ecef; /* Light hover background */
    --bs-dropdown-link-hover-color: #000000; /* Light hover text */

    /* Light mode scrollbar colors */
    --scrollbar-thumb-color: #7ca1c5; /* Thumb color */
    --scrollbar-track-color: #d9e2e9; /* Track color */
    --scrollbar-thumb-hover-color: #05315a; /* Hovered thumb color */
}

/* Dark Mode Variables */
.dark-mode {
    --root-text-color: white;
    --root-background-color: #1a1a1a;

    --main: #000;
    --white: #2e2e2e;
    --dark: #f8f9fa;
    --secondary: #8b9299;
    --white-absolute: #fff;

    --bs-tertiary-bg: var(--bs-body-bg);

    --primary-0: #1a1a1a;
    --primary-5: #212121;
    --primary-10: #212121;
    --primary-20: #404040;
    --primary-30: #4d4d4d;
    --primary-40: #595959;
    --primary-50: #666666;
    --primary-60: #737373;
    --primary-70: #808080;
    --primary-80: #8c8c8c;
    --primary-90: #001c36;
    --primary-100: #a6a6a6;

    --warning-10: #403a20;
    --warning-20: #4d4726;
    --warning-30: #665d33;
    --warning-40: #807641;
    --warning-50: #9a8f4f;
    --warning-60: #b3a75c;
    --warning-70: #cdb06a;
    --warning-80: #e6b978;
    --warning-90: #ffcd87;
    --warning-100: #ffd99b;

    --success-10: #203e25;
    --success-20: #2a5032;
    --success-30: #3b6a46;
    --success-40: #4d855b;
    --success-50: #5f9f70;
    --success-60: #70b985;
    --success-70: #82d39a;
    --success-80: #93e9af;
    --success-90: #a4f6c3;
    --success-100: #b6ffd7;

    --neutral-10: #1a1a1a;
    --neutral-20: #262626;
    --neutral-30: #333333;
    --neutral-40: #404040;
    --neutral-50: #4d4d4d;
    --neutral-60: #595959;
    --neutral-70: #666666;
    --neutral-80: #737373;
    --neutral-90: #808080;
    --neutral-100: #8c8c8c;

    --gradient-start: #222222;
    --gradient-end: #10121c;
    --gradient-horizontal-start: #292929;
    --gradient-horizontal-end: #0d0d0d;
    --gradient-sm-start: #10121c;
    --gradient-sm-end: #0f0f0f;

    --modal-header: #0f0f0f;

    --pre-background: #1f1f1f;
    --pre-border: #444;
    --pre-border-left: #ff5722;
    --pre-text-color: #e0e0e0;

    --btn-success-bg: #095f38;
    --btn-success-text: #fff;
    --btn-success-border: #095f38;
    --btn-success-hover-bg: #022d25;
    --btn-success-hover-border: #022d25;
    --btn-success-active-bg: #000;
    --btn-success-active-text: #0e8f54;
    --btn-success-active-border: #000;
    --btn-disabled-bg: #4a4a4a;
    --btn-no-bg-disabled-color: #939393;
    --btn-disabled-text: #999;
    --btn-disabled-border: #444;
    --btn-primary-bg: #1c4a7f;
    --btn-primary-hover-bg: #35597b;
    --btn-primary-text: #fff;
    --btn-primary-dark-bg: #012341;
    --btn-primary-dark-hover-bg: #08263e;
    --btn-primary-dark-text: #fff;
    --btn-secondary-bg: #2e2e2e;
    --btn-secondary-text: #aaa;
    --btn-secondary-border: #555;
    --btn-no-bg-text: #d1d1d1;
    --btn-no-bg-hover-text: #8c8c8c;
    --btn-tab-text: #d1d1d1;
    --btn-tab-hover-text: #e0e0e0;
    --btn-nav-text: #59d48a;
    --btn-nav-hover-text: #fff;
    --btn-transparent-bg: transparent;
    --btn-transparent-text: #d1d1d1;
    --btn-transparent-text-hover: #000;
    --btn-transparent-border: #444;

    --bs-body-color: #e0e0e0;
    --bs-body-bg: var(--white);

    --bs-btn-primary-bg: #1a73e8;
    --bs-btn-primary-color: white;
    --bs-btn-secondary-bg: #6c757d;
    --bs-btn-secondary-color: white;

    --bs-form-control-bg: #333333;
    --bs-form-control-color: #e0e0e0;
    --bs-input-border-color: #444444;

    --bs-card-bg: var(--white);
    --bs-card-color: #e0e0e0;
    --bs-card-border-color: #444444;

    --bs-modal-bg: #252525;
    --bs-modal-color: #e0e0e0;
    --bs-backdrop: rgba(94, 94, 94, 0.931); /* Example for dark mode */

    --form-check-bg: rgb(96, 96, 96) !important;

    --bs-alert-bg: #444444;
    --bs-alert-border-color: #555555;
    --bs-alert-color: white;

    --bs-tooltip-bg: #444444;
    --bs-tooltip-color: white;

    --bs-border-color: #575757;

    /* Dropdown */
    --bs-dropdown-bg: #2e2e2e; /* Dark background */
    --bs-dropdown-color: #ffffff; /* Dark text */
    --bs-dropdown-border-color: #7e7e7e; /* Dark border */
    --bs-dropdown-link-hover-bg: #444444; /* Dark hover background */
    --bs-dropdown-link-hover-color: #ffffff; /* Dark hover text */

    --bs-secondary-bg: var(--primary-50);

    /* Dark mode scrollbar colors */
    --scrollbar-thumb-color: #333333;
    --scrollbar-track-color: #101010;
    --scrollbar-thumb-hover-color: #ffffff;
}
