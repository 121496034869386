:root {
    --chart-bg: #ffffff;
    --text-color: #000000;
    --grid-line-color: #e6e6e6;
    --tooltip-bg: #f9f9f9;
    --tooltip-text: #000000;

    --navigator-bg: #7e8de262;

    --menu-bg: #ffffff;
    --menu-text: #000000;
    --menu-hover-bg: #e6e6e6;
    --menu-hover-text: #000000;
    --menu-padding: 8px 16px;
    --menu-border: none;
    --tick-color: #e6e6e6; /* Tick color for light mode */
}

.dark-mode {
    --chart-bg: #2e2e2e;
    --text-color: #ffffff;
    --grid-line-color: #848484;
    --tooltip-bg: #333333;
    --tooltip-text: #ffffff;

    --navigator-bg: #2e43b762;

    --menu-bg: #333333;
    --menu-text: #ffffff;
    --menu-hover-bg: #444444;
    --menu-hover-text: #ffffff;
    --menu-padding: 8px 16px;
    --menu-border: none;
    --tick-color: #ffffff; /* Tick color for light mode */
}

.highcharts-contextmenu .highcharts-menu {
    background-color: var(--menu-bg) !important; /* Exporting menu background */
    color: var(--menu-text) !important; /* Exporting menu text */
    border: 1px solid var(--menu-border) !important;
}

.highcharts-contextmenu .highcharts-menu-item {
    background-color: var(--menu-bg) !important; /* Menu item background */
    color: var(--menu-text) !important; /* Menu item text */
    padding: 8px 16px; /* Adjust padding */
}

.highcharts-contextmenu .highcharts-menu-item:hover {
    background-color: var(--menu-hover-bg) !important; /* Hover background */
    color: var(--menu-hover-text) !important; /* Hover text */
}

.highcharts-grid-line {
    stroke: var(--grid-line-color) !important; /* Grid line color */
}

.highcharts-axis-line {
    stroke: var(--grid-line-color) !important; /* Axis line color */
}

.highcharts-axis-labels {
    fill: var(--text-color) !important; /* Axis text color */
}

/* Target Highcharts ticks */
.highcharts-tick {
    stroke: var(--tick-color) !important;
    stroke-width: 1px;
}

/* Axis Labels */
.highcharts-axis-labels text {
    fill: var(--label-color) !important; /* Overrides inline 'fill' */
    color: var(--label-color) !important; /* Ensures compatibility */
    font-size: 10px !important; /* Adjust font size if needed */
}
