@import './variables.css';

/* Neutral 10 */
.remotive-neutral-10-color {
    color: var(--neutral-10) !important;
}

.remotive-neutral-10-background {
    background-color: var(--neutral-10) !important;
}

.remotive-neutral-10-border {
    border-color: var(--neutral-10) !important;
    border-style: solid;
    border-width: 2px;
}

/* Neutral 20 */
.remotive-neutral-20-color {
    color: var(--neutral-20) !important;
}

.remotive-neutral-20-background {
    background-color: var(--neutral-20) !important;
}

.remotive-neutral-20-border {
    border-color: var(--neutral-20) !important;
    border-style: solid;
    border-width: 2px;
}

/* Neutral 30 */
.remotive-neutral-30-color {
    color: var(--neutral-30) !important;
}

.remotive-neutral-30-background {
    background-color: var(--neutral-30) !important;
}

.remotive-neutral-30-border {
    border-color: var(--neutral-30) !important;
    border-style: solid;
    border-width: 2px;
}

/* Neutral 40 */
.remotive-neutral-40-color {
    color: var(--neutral-40) !important;
}

.remotive-neutral-40-background {
    background-color: var(--neutral-40) !important;
}

.remotive-neutral-40-border {
    border-color: var(--neutral-40) !important;
    border-style: solid;
    border-width: 2px;
}

/* Neutral 50 */
.remotive-neutral-50-color {
    color: var(--neutral-50) !important;
}

.remotive-neutral-50-background {
    background-color: var(--neutral-50) !important;
}

.remotive-neutral-50-border {
    border-color: var(--neutral-50) !important;
    border-style: solid;
    border-width: 2px;
}

/* Neutral 60 */
.remotive-neutral-60-color {
    color: var(--neutral-60) !important;
}

.remotive-neutral-60-background {
    background-color: var(--neutral-60) !important;
}

.remotive-neutral-60-border {
    border-color: var(--neutral-60) !important;
    border-style: solid;
    border-width: 2px;
}

/* Neutral 70 */
.remotive-neutral-70-color {
    color: var(--neutral-70) !important;
}

.remotive-neutral-70-background {
    background-color: var(--neutral-70) !important;
}

.remotive-neutral-70-border {
    border-color: var(--neutral-70) !important;
    border-style: solid;
    border-width: 2px;
}

/* Neutral 80 */
.remotive-neutral-80-color {
    color: var(--neutral-80) !important;
}

.remotive-neutral-80-background {
    background-color: var(--neutral-80) !important;
}

.remotive-neutral-80-border {
    border-color: var(--neutral-80) !important;
    border-style: solid;
    border-width: 2px;
}

/* Neutral 90 */
.remotive-neutral-90-color {
    color: var(--neutral-90) !important;
}

.remotive-neutral-90-background {
    background-color: var(--neutral-90) !important;
}

.remotive-neutral-90-border {
    border-color: var(--neutral-90) !important;
    border-style: solid;
    border-width: 2px;
}

/* Neutral 100 */
.remotive-neutral-100-color {
    color: var(--neutral-100) !important;
}

.remotive-neutral-100-background {
    background-color: var(--neutral-100) !important;
}

.remotive-neutral-100-border {
    border-color: var(--neutral-100) !important;
    border-style: solid;
    border-width: 2px;
}
