@import './variables.css';

/* Warning 10 */
.remotive-warning-10-color {
    color: var(--warning-10) !important;
}

.remotive-warning-10-background {
    background-color: var(--warning-10) !important;
}

.remotive-warning-10-border {
    border-color: var(--warning-10) !important;
    border-style: solid;
    border-width: 2px;
}

/* Warning 20 */
.remotive-warning-20-color {
    color: var(--warning-20) !important;
}

.remotive-warning-20-background {
    background-color: var(--warning-20) !important;
}

.remotive-warning-20-border {
    border-color: var(--warning-20) !important;
    border-style: solid;
    border-width: 2px;
}

/* Warning 30 */
.remotive-warning-30-color {
    color: var(--warning-30) !important;
}

.remotive-warning-30-background {
    background-color: var(--warning-30) !important;
}

.remotive-warning-30-border {
    border-color: var(--warning-30) !important;
    border-style: solid;
    border-width: 2px;
}

/* Warning 40 */
.remotive-warning-40-color {
    color: var(--warning-40) !important;
}

.remotive-warning-40-background {
    background-color: var(--warning-40) !important;
}

.remotive-warning-40-border {
    border-color: var(--warning-40) !important;
    border-style: solid;
    border-width: 2px;
}

/* Warning 50 */
.remotive-warning-50-color {
    color: var(--warning-50) !important;
}

.remotive-warning-50-background {
    background-color: var(--warning-50) !important;
}

.remotive-warning-50-border {
    border-color: var(--warning-50) !important;
    border-style: solid;
    border-width: 2px;
}

/* Warning 60 */
.remotive-warning-60-color {
    color: var(--warning-60) !important;
}

.remotive-warning-60-background {
    background-color: var(--warning-60) !important;
}

.remotive-warning-60-border {
    border-color: var(--warning-60) !important;
    border-style: solid;
    border-width: 2px;
}

/* Warning 70 */
.remotive-warning-70-color {
    color: var(--warning-70) !important;
}

.remotive-warning-70-background {
    background-color: var(--warning-70) !important;
}

.remotive-warning-70-border {
    border-color: var(--warning-70) !important;
    border-style: solid;
    border-width: 2px;
}

/* Warning 80 */
.remotive-warning-80-color {
    color: var(--warning-80) !important;
}

.remotive-warning-80-background {
    background-color: var(--warning-80) !important;
}

.remotive-warning-80-border {
    border-color: var(--warning-80) !important;
    border-style: solid;
    border-width: 2px;
}

/* Warning 90 */
.remotive-warning-90-color {
    color: var(--warning-90) !important;
}

.remotive-warning-90-background {
    background-color: var(--warning-90) !important;
}

.remotive-warning-90-border {
    border-color: var(--warning-90) !important;
    border-style: solid;
    border-width: 2px;
}

/* Warning 100 */
.remotive-warning-100-color {
    color: var(--warning-100) !important;
}

.remotive-warning-100-background {
    background-color: var(--warning-100) !important;
}

.remotive-warning-100-border {
    border-color: var(--warning-100) !important;
    border-style: solid;
    border-width: 2px;
}
