@import './variables.css';

/* Apply Variables to Classes */
.remotive-primary-0-color {
    color: var(--primary-0) !important;
}

.remotive-primary-0-background {
    background-color: var(--primary-0) !important;
}

.remotive-primary-0-border {
    border-color: var(--primary-0) !important;
    border-style: solid;
    border-width: 2px;
}

.remotive-primary-5-color {
    color: var(--primary-5) !important;
}

.remotive-primary-5-background {
    background-color: var(--primary-5) !important;
}

.remotive-primary-5-border {
    border-color: var(--primary-5) !important;
    border-style: solid;
    border-width: 2px;
}

.remotive-primary-10-color {
    color: var(--primary-10) !important;
}

.remotive-primary-10-background {
    background-color: var(--primary-10) !important;
}

.remotive-primary-10-border {
    border-color: var(--primary-10) !important;
    border-style: solid;
    border-width: 2px;
}

.remotive-primary-20-color {
    color: var(--primary-20) !important;
}

.remotive-primary-20-background {
    background-color: var(--primary-20) !important;
}

.remotive-primary-20-border {
    border-color: var(--primary-20) !important;
    border-style: solid;
    border-width: 2px;
}

.remotive-primary-30-color {
    color: var(--primary-30) !important;
}

.remotive-primary-30-background {
    background-color: var(--primary-30) !important;
}

.remotive-primary-30-border {
    border-color: var(--primary-30) !important;
    border-style: solid;
    border-width: 2px;
}

.remotive-primary-40-color {
    color: var(--primary-40) !important;
}

.remotive-primary-40-background {
    background-color: var(--primary-40) !important;
}

.remotive-primary-40-border {
    border-color: var(--primary-40) !important;
    border-style: solid;
    border-width: 2px;
}

.remotive-primary-50-color {
    color: var(--primary-50) !important;
}

.remotive-primary-50-color-absolute{
    color: var(--primary-50-absolute) !important;
}

.remotive-primary-50-background {
    background-color: var(--primary-50) !important;
}

.remotive-primary-50-border {
    border-color: var(--primary-50) !important;
    border-style: solid;
    border-width: 2px;
}

.remotive-primary-60-color {
    color: var(--primary-60) !important;
}

.remotive-primary-60-background {
    background-color: var(--primary-60) !important;
}

.remotive-primary-60-border {
    border-color: var(--primary-60) !important;
    border-style: solid;
    border-width: 2px;
}

.remotive-primary-70-color {
    color: var(--primary-70) !important;
}

.remotive-primary-70-background {
    background-color: var(--primary-70) !important;
}

.remotive-primary-70-border {
    border-color: var(--primary-70) !important;
    border-style: solid;
    border-width: 2px;
}

.remotive-primary-80-color {
    color: var(--primary-80) !important;
}

.remotive-primary-80-background {
    background-color: var(--primary-80) !important;
}

.remotive-primary-80-border {
    border-color: var(--primary-80) !important;
    border-style: solid;
    border-width: 2px;
}

.remotive-primary-90-color {
    color: var(--primary-90) !important;
}

.remotive-primary-90-background {
    background-color: var(--primary-90) !important;
}

.remotive-primary-90-border {
    border-color: var(--primary-90) !important;
    border-style: solid;
    border-width: 2px;
}

.remotive-primary-100-color {
    color: var(--primary-100) !important;
}

.remotive-primary-100-background {
    background-color: var(--primary-100) !important;
}

.remotive-primary-100-border {
    border-color: var(--primary-100) !important;
    border-style: solid;
    border-width: 2px;
}
