:root {
    --parent-background: #f0f8ff;
}

.dark-mode {
    --parent-background: #121212;
}

.parent-row {
    background-color: var(--parent-background); /* Light blue for parent rows */
}