:root {
    --select-bg: #ffffff;
    --select-border: #cccccc;
    --select-text: #000000;
    --select-placeholder: #666666;
    --select-hover-border: #999999;
    --select-option-bg: #ffffff;
    --select-option-hover-bg: #f0f0f0;
    --select-option-selected-bg: #e0e0e0;
    --select-option-text: #000000;
    --select-indicator-color: #666666;
}

.dark-mode {
    --select-bg: #1a1a1a;
    --select-border: #333333;
    --select-text: #e0e0e0;
    --select-placeholder: #aaaaaa;
    --select-hover-border: #555555;
    --select-option-bg: #2a2a2a;
    --select-option-hover-bg: #444444;
    --select-option-selected-bg: #555555;
    --select-option-text: #ffffff;
    --select-indicator-color: #cccccc;
}

/* Container */
.react-select-container {
    width: 100%;
}

/* Control (Input Field) */
.react-select__control {
    background-color: var(--select-bg) !important;
    border-color: var(--select-border) !important;
    color: var(--select-text) !important;
}

.react-select__control:hover {
    border-color: var(--select-hover-border) !important;
}

/* Placeholder */
.react-select__placeholder {
    color: var(--select-placeholder) !important;
}

/* Single Value (Selected Item) */
.react-select__single-value {
    color: var(--select-text) !important;
}

/* Menu (Dropdown) */
.react-select__menu {
    background-color: var(--select-option-bg) !important;
    color: var(--select-option-text) !important;
}

/* Options */
.react-select__option {
    background-color: var(--select-option-bg) !important;
    color: var(--select-option-text) !important;
}

.react-select__option--is-focused {
    background-color: var(--select-option-hover-bg) !important;
}

.react-select__option--is-selected {
    background-color: var(--select-option-selected-bg) !important;
    color: var(--select-option-text) !important;
}

/* Indicators (Dropdown Arrow, Clear Button) */
.react-select__indicator {
    color: var(--select-indicator-color) !important;
}

.react-select__indicator:hover {
    color: var(--select-text) !important;
}
