@import './variables.css';

/* Success 10 */
.remotive-success-10-color {
    color: var(--success-10) !important;
}

.remotive-success-10-background {
    background-color: var(--success-10) !important;
}

.remotive-success-10-border {
    border-color: var(--success-10) !important;
    border-style: solid;
    border-width: 2px;
}

/* Success 20 */
.remotive-success-20-color {
    color: var(--success-20) !important;
}

.remotive-success-20-background {
    background-color: var(--success-20) !important;
}

.remotive-success-20-border {
    border-color: var(--success-20) !important;
    border-style: solid;
    border-width: 2px;
}

/* Success 30 */
.remotive-success-30-color {
    color: var(--success-30) !important;
}

.remotive-success-30-background {
    background-color: var(--success-30) !important;
}

.remotive-success-30-border {
    border-color: var(--success-30) !important;
    border-style: solid;
    border-width: 2px;
}

/* Success 40 */
.remotive-success-40-color {
    color: var(--success-40) !important;
}

.remotive-success-40-background {
    background-color: var(--success-40) !important;
}

.remotive-success-40-border {
    border-color: var(--success-40) !important;
    border-style: solid;
    border-width: 2px;
}

/* Success 50 */
.remotive-success-50-color {
    color: var(--success-50) !important;
}

.remotive-success-50-background {
    background-color: var(--success-50) !important;
}

.remotive-success-50-border {
    border-color: var(--success-50) !important;
    border-style: solid;
    border-width: 2px;
}

/* Success 60 */
.remotive-success-60-color {
    color: var(--success-60) !important;
}

.remotive-success-60-background {
    background-color: var(--success-60) !important;
}

.remotive-success-60-border {
    border-color: var(--success-60) !important;
    border-style: solid;
    border-width: 2px;
}

/* Success 70 */
.remotive-success-70-color {
    color: var(--success-70) !important;
}

.remotive-success-70-background {
    background-color: var(--success-70) !important;
}

.remotive-success-70-border {
    border-color: var(--success-70) !important;
    border-style: solid;
    border-width: 2px;
}

/* Success 80 */
.remotive-success-80-color {
    color: var(--success-80) !important;
}

.remotive-success-80-background {
    background-color: var(--success-80) !important;
}

.remotive-success-80-border {
    border-color: var(--success-80) !important;
    border-style: solid;
    border-width: 2px;
}

/* Success 90 */
.remotive-success-90-color {
    color: var(--success-90) !important;
}

.remotive-success-90-background {
    background-color: var(--success-90) !important;
}

.remotive-success-90-border {
    border-color: var(--success-90) !important;
    border-style: solid;
    border-width: 2px;
}

/* Success 100 */
.remotive-success-100-color {
    color: var(--success-100) !important;
}

.remotive-success-100-background {
    background-color: var(--success-100) !important;
}

.remotive-success-100-border {
    border-color: var(--success-100) !important;
    border-style: solid;
    border-width: 2px;
}
