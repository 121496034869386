

@font-face {
    font-family: 'LexendDecaRegular';
    src: local('LexendDecaRegular'), url(../fonts/LexendDeca-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'LexendDecaLight';
    src: local('LexendDecaLight'), url(../fonts/LexendDeca-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'LexendDecaBold';
    src: local('LexendDecaBold'), url(../fonts/LexendDeca-Bold.ttf) format('truetype');
}

b {
    font-family: 'LexendDecaBold' !important;
    font-weight: 400 !important;
}

.lexend-light {
    font-family: 'LexendDecaLight' !important;
}

.lexend-regular {
    font-family: 'LexendDecaRegular' !important;
}

.lexend-bold {
    font-family: 'LexendDecaBold' !important;
}

.remotive-font-xl {
    font-size: 22px !important;
}

.remotive-font-lg {
    font-size: 18px !important;
}

.remotive-font {
    font-size: 16px !important;
}

.remotive-font-md {
    font-size: 14px !important;
}

.remotive-font-sm {
    font-size: 12px !important;
}

.remotive-font-xs {
    font-size: 10px;
}

.remotive-font-xxs {
    font-size: 9px;
}

.remotive-font-xxxs {
    font-size: 8px;
}
