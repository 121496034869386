@import './variables.css'; /* Dark mode and light mode variables */

/* Success Buttons */
.remotive-btn-success {
    background-color: var(--btn-success-bg) !important;
    color: var(--btn-success-text) !important;
    border-color: var(--btn-success-border) !important;
}

.remotive-btn-success:hover {
    background-color: var(--btn-success-hover-bg) !important;
    border-color: var(--btn-success-hover-border) !important;
}

.remotive-btn-success:active {
    background-color: var(--btn-success-active-bg) !important;
    color: var(--btn-success-active-text) !important;
    border-color: var(--btn-success-active-border) !important;
}

.remotive-btn-success:disabled {
    background-color: var(--btn-disabled-bg) !important;
    color: var(--btn-disabled-text) !important;
    border-color: var(--btn-disabled-border) !important;
}

/* Primary Buttons */
.remotive-btn-primary {
    background-color: var(--btn-primary-bg) !important;
    color: var(--btn-primary-text) !important;
    border-color: var(--btn-primary-bg) !important;
}

.remotive-btn-primary:hover {
    background-color: var(--btn-primary-hover-bg) !important;
    border-color: var(--btn-primary-hover-bg) !important;
}

/* Primary Dark Buttons */
.remotive-btn-primary-dark {
    background-color: var(--btn-primary-dark-bg) !important;
    color: var(--btn-primary-dark-text) !important;
    border-color: var(--btn-primary-dark-bg) !important;
}

.remotive-btn-primary-dark:hover {
    background-color: var(--btn-primary-dark-hover-bg) !important;
    border-color: var(--btn-primary-dark-hover-bg) !important;
}

/* Secondary Buttons */
.remotive-btn-secondary {
    background-color: var(--btn-secondary-bg) !important;
    color: var(--btn-secondary-text) !important;
    border-color: var(--btn-secondary-border) !important;
}

.remotive-btn-secondary:hover {
    background-color: var(--btn-primary-hover-bg) !important;
    color: var(--btn-primary-text) !important;
    border-color: var(--btn-primary-hover-bg) !important;
}

/* No Background Buttons */
.remotive-btn-no-bg {
    background-color: transparent !important;
    color: var(--btn-no-bg-text) !important;
}

.remotive-btn-no-bg:hover {
    color: var(--btn-no-bg-hover-text) !important;
}

.remotive-btn-no-bg:disabled {
    color: var(--btn-no-bg-disabled-color) !important;
    border: 0 !important;
}

/* Tab Buttons */
.remotive-btn-tab {
    background-color: transparent !important;
    color: var(--btn-tab-text) !important;
}

.remotive-btn-tab:hover {
    color: var(--btn-tab-hover-text) !important;
}

/* Nav Buttons */
.remotive-btn-nav {
    background-color: transparent !important;
    color: var(--btn-nav-text) !important;
    font-weight: normal;
}

.remotive-btn-nav:hover {
    color: var(--btn-nav-hover-text) !important;
    font-weight: bold;
}

/* Transparent Buttons */
.remotive-btn-transparent {
    background-color: var(--btn-transparent-bg) !important;
    color: var(--btn-transparent-text) !important;
    border-color: var(--btn-transparent-border) !important;
}

.remotive-btn-transparent:hover {
    background-color: var(--btn-transparent-text) !important;
    color: var(--btn-transparent-text-hover) !important;
    border-color: var(--btn-transparent-bg) !important;
}

/* Disabled Buttons */
button:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

/* Add smooth transitions */
button {
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

/* Button sizes */

.remotive-btn {
    border-radius: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.remotive-btn-md {
    border-radius: 50px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 12px;
}

.remotive-btn-sm {
    border-radius: 50px;
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin: 5px;
    font-size: 12px;
}