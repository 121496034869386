@import './variables.css';

.text-light {
    color: var(--text-light);
}

/* Styled pre Element */
.code-box {
    background: var(--pre-background);
    border: 1px solid var(--pre-border);
    border-left: 3px solid var(--pre-border-left);
    color: var(--pre-text-color);
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

/* Gradient Backgrounds */
.remotive-gradient {
    background: radial-gradient(
        183.91% 56.41% at 13.33% 6.53%,
        var(--gradient-start) 13.13%,
        var(--gradient-end) 93.52%
    ) !important;
}

.remotive-gradient-horizontal {
    background: radial-gradient(
        100.41% 183.91% at 13.33% 6.53%,
        var(--gradient-horizontal-start) 13.13%,
        var(--gradient-horizontal-end) 103.52%
    ) !important;
}

.remotive-gradient-sm {
    background: radial-gradient(
        183.91% 96.41% at 53.33% 15.53%,
        var(--gradient-sm-start) 13.13%,
        var(--gradient-sm-end) 93.52%
    ) !important;
}

.remotive-main-background {
    background-color: var(--main);
}

.remotive-white-background {
    background-color: var(--white) !important;
    background: var(--white) !important;
}

.remotive-white-color {
    color: var(--white) !important;
}

.remotive-white-color-absolute {
    color: var(--white-absolute) !important;
}

.remotive-dark-color {
    color: var(--dark) !important;
}

.remotive-secondary-color {
    color: var(--secondary) !important;
}

.dark-and-light-placeholder::placeholder {
    color: var(--dark)  !important; /* Replace with the appropriate variable or color value */
    opacity: 0.65  !important; /* Ensure the placeholder is fully opaque */
}